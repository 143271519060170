@import 'reset';
@import 'typography';
@import 'buttons';
@import 'ui';
@import 'colors';
@import 'ngx-modal';

html, body {
  height: 100%;
  color: $color-neutral;
  font-family: PrimaryFont, sans-serif;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: rgba(var(--color-neutral-rgb), 0.1);
}

::-webkit-scrollbar-corner {
    background: rgba(var(--color-neutral-rgb), 0.1);
}

/* For Webkit browsers like Chrome, Safari */

::-webkit-scrollbar-thumb {
  background: rgba(var(--color-neutral-rgb), 0.25);
  border-radius: 10px;
  height: 10px;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: auto;
    scrollbar-color: rgba(var(--color-neutral-rgb), 0.1) rgba(var(--color-neutral-rgb), 0.25);
  }
}