@import 'typography';
@import 'colors';
@import 'sizes';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: PrimaryFont, sans-serif;
  border-radius: 24px;
  border: 2px solid transparent;
  text-align: center;
  transition: transform 0.1s;
  cursor: pointer;
  padding: 15px 40px;
}

.full-width-btn {
  width: 100%;
}

.primary-button {
  @extend .button;
  @extend .text-small;
  background: var(--z-cta-buttons-primary-background-color, map-get($color-accents, 1));
  color: var(--z-cta-buttons-primary-text-color, $color-neutral);
}

.secondary-button {
  @extend .button;
  @extend .text-small;
  background: $color-neutral;
  color: map-get($color-accents, 1);
}

.tertiary-button {
  @extend .button;
  @extend .text-small;
  background: transparent;
  border-color: var(--z-cta-buttons-outline-background-color, map-get($color-accents, 1));
  color: var(--z-cta-buttons-outline-text-color, $color-neutral);
}

.primary-button:disabled,
.secondary-button:disabled,
.tertiary-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: var(--z-cta-buttons-disabled-background-color, map-get($color-accents, 1));
  color: var(--z-cta-buttons-disabled-text-color, $color-neutral);
}

.transparent-btn {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.mobile-version {
  .button {
    padding: 11px 40px;
  }
}
