@import 'colors';

.uppercase {
  text-transform: uppercase;
}

.cursor {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

// Layout styles
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}
