/* Recommended: 0.2s linear */
@keyframes enter-scaling-mobile {
  from {
    transform: scale(0.8) translate(-50%, -100%);
    transform-origin: left;
  }
  to {
    transform: scale(1) translate(-50%, -100%);
    transform-origin: left;
  }
}

.mobile-version .ngx-modal {
  transform: translate(-50%, -100%);
}
