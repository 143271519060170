// Baseline
$color-base: (
  1: var(--color-base-1, #001769),
  3: var(--color-base-3, #000C35),
);

// Accent
$color-accents: (
  1: var(--color-accent-1, #2F44FE),
  2: var(--color-accent-2, #06EDC4),
);

// Neutral
$color-neutral: var(--color-neutral, #FFFFFF);
$color-neutral-2: #FFFFFF; // don't exist on the backend, just here for the elements which are always white